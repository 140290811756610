import { useState } from "react";
import { useQuery } from "react-query";

import { BankIDWithOptions, UIText, VStack, Link, useModal } from "therese";
import { ReactComponent as ChevronRight } from "symbols/chevron_right.svg";

import { useProfile } from "../../components/useProfile";
import { Container } from "../../components/Layout/Layout";
import { Logo } from "../../components/Logo";

import Phone from "./Phone";
import styles from "./styles.module.css";
import PrivacyModal from "./PrivacyModal";
import { useProperties } from "../../components/useProperties";

function Login() {
  const modal = useModal();

  const [state, setState] = useState<"phone" | "bankid">("phone");
  const { refetch } = useProfile("user", { enabled: false });
  const { refetch: refetchProp } = useProperties({ enabled: false });
  const { data } = useQuery<{ mobile: string; normal: string }>(
    "login/bankid",
    {
      enabled: state === "bankid",
    }
  );
  async function handleSuccess() {
    await refetch();
    await refetchProp();
  }
  return (
    <>
      <Container padding="s" className={styles.root} width="narrow">
        <VStack spacing="l" responsive>
          <Logo centered className={styles.center} />
          <div>
            <VStack spacing="m" responsive>
              <UIText className={styles.center} size="28" as="h2">
                Logg inn
              </UIText>

              <div className={styles.content}>
                <VStack spacing="s">
                  {state === "phone" ? (
                    <Phone onSuccess={handleSuccess} />
                  ) : (
                    <BankIDWithOptions
                      options={[
                        {
                          label: "BankID",
                          src: data?.normal,
                        },
                        {
                          label: "BankID på mobil",
                          src: data?.mobile,
                        },
                      ]}
                      onSuccess={handleSuccess}
                    />
                  )}
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Link
                      icon={ChevronRight}
                      theme="none"
                      type="button"
                      onClick={() =>
                        setState(state === "phone" ? "bankid" : "phone")
                      }
                    >
                      {`Logg inn med ${state === "phone" ? "BankID" : "SMS"}`}
                    </Link>
                  </div>
                </VStack>
              </div>

              <UIText className={styles.privacy} size="14">
                DNB Eiendom AS er behandlingsansvarlig og behandler ditt telefonnummer og bankId for å verifisere ditt
                kundeforhold og gi deg tilgang til DNB Eiendoms kundeportal, hvor vi behandler informasjon om deg og
                ditt oppdrag ved verdivurdering/e-takst, salg og kjøp. Du kan lese mer om hvordan DNB Eiendom
                behandler personopplysninger, herunder om dine rettigheter til blant annet innsyn, retting og sletting, i vår { }
                <a href="https://dnbeiendom.no/om-oss/personvern" target="_blank" rel="noreferrer">
                  personvernerklæring på dnbeiendom.no
                </a>
              </UIText>
            </VStack>
          </div>
        </VStack>
      </Container>
      <PrivacyModal isOpen={modal.open} dismiss={modal.dismiss} />
    </>
  );
}

export default Login;
