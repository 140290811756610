import { useState } from "react";
import redaxios from "redaxios";

import {
  VStack,
  Form,
  FormikInputText,
  FormikInputCode,
  Label,
  Button,
  Loading,
} from "therese";

import { object, phone, string } from "../../utils/validation";

interface IPhoneProps {
  onSuccess: () => void;
}

function Phone(props: IPhoneProps) {
  const [state, setState] = useState<"number" | "code">("number");
  const [completed, setCompleted] = useState(false);
  const [codeKey, setCodeKey] = useState<string | number>("");

  async function handleSubmit(values: IT, formik: any) {
    try {
      await submit[state](values);
      if (state === "number") {
        setState("code");
        formik.setSubmitting(false);
      } else {
        setCompleted(true);
        props.onSuccess();
      }
    } catch (error) {
      formik.setFieldError(state, error.message);
      if (state === "code") {
        formik.setFieldValue(state, "", false);
        setCodeKey(Math.random());
      }
      formik.setSubmitting(false);
    }
  }

  if (completed) {
    return <Loading />;
  }

  return (
    <Form
      initialValues={{ number: "", code: "" }}
      validationSchema={object({
        number: phone().label("Mobilnummer").required(),
        code: string().label("Engangskode").when("number", {
          is: phone(),
          then: string().required(),
        }),
      })}
      onSubmit={handleSubmit}
      spacing="s"
    >
      {(formik) => {
        return (
          <VStack spacing="m">
            {state === "number" ? (
              <VStack>
                <Label htmlFor="number">Mobilnummer</Label>
                <FormikInputText
                  placeholder="Eksempel: 123 45 678…"
                  autoComplete="tel-national"
                  name="number"
                  id="number"
                  inputMode="tel"
                />
              </VStack>
            ) : (
              <VStack>
                <FormikInputCode
                  key={codeKey}
                  label="Engangskode"
                  name="code"
                  id="code"
                />
                <Button
                  type="button"
                  size="small"
                  theme="outline"
                  onClick={() => handleSubmit(formik.values, formik)}
                >
                  Send engangskode på nytt
                </Button>
              </VStack>
            )}

            <Button
              type="submit"
              size="large"
              theme="action"
              disabled={formik.isSubmitting}
            >
              {state === "number" ? "Logg inn med SMS" : "Bekreft engangskode"}
            </Button>
          </VStack>
        );
      }}
    </Form>
  );
}

interface IT {
  number: string;
  code?: string;
}

const submit = {
  async number({ number }: IT) {
    try {
      const response = await redaxios.post("/api/login/sms", { number });
      if (response.data.status === 204) {
        throw new Error("Vennligst vent 30 sekunder etter sist kodeutsending");
      }
    } catch (error) {
      if (
        error.message === "Vennligst vent 30 sekunder etter sist kodeutsending"
      ) {
        console.log("mute");
      } else {
        throw new Error("Kode kan ikke sendes, ta kontakt med megler");
      }
    }
  },
  async code(values: IT) {
    try {
      await redaxios.get("/api/login/sms", { params: values });
    } catch (error) {
      this.number(values);
      throw new Error("Feil kode. Vi sender en ny engangskode");
    }
  },
};

export default Phone;
